import { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	Typography,
	Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthStore } from '../../../lib/context/useAuthStore';
import { Profile } from '../../../lib/type';
import { LoadingButton } from '@mui/lab';

interface ProfileSelectorProps {
	open: boolean;
	profiles: Profile[];
	onSelect: (profileId: number, email: string) => Promise<void>;
  }

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiDialog-paper': {
			minWidth: '600px',
			padding: '20px',
		},
	},
	title: {
		'&.MuiDialogTitle-root': {
			padding: 0,
			marginBottom: '32px',
			fontWeight: 'bold',
		},
	},
	content: {
		padding: '24px 0',
		'&.MuiDialogContent-root': {
			padding: '5px 0',
		},
	},
	description: {
		marginBottom: '32px',
	},
	radioGroup: {
		padding: '24px 0',
		marginBottom: '48px',
	},
	formControl: {
		marginBottom: '0px',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
	signOutButton: {
		color: '#000',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
	},
	continueButton: {
		borderRadius: '4px',
	},
}));



export function ProfileSelector({ open, profiles, onSelect }: ProfileSelectorProps) {
	const classes = useStyles();
	const [selectedProfile, setSelectedProfile] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const { logout } = useAuthStore();
  
	const handleConfirm = async () => {
		if (!selectedProfile) return;
	
		const profile = profiles.find((p) => Number(p.id) === Number(selectedProfile));
		
		if (!profile) {
		  console.error('Profile not found');
		  return;
		}
	
		try {
		  setIsLoading(true);
		  await onSelect(profile.id, profile.email); 
		} catch (error) {
		  console.error('Profile selection failed:', error);
		} finally {
		  setIsLoading(false);
		}
	  };
	
	  // Reset selection when dialog opens
	  useEffect(() => {
		if (open) {
		  setSelectedProfile('');
		}
	  }, [open]);
  
	const handleSignOut = async () => {
	  try {
		setIsLoading(true);
		await logout();
	  } catch (error) {
		console.error('Logout failed:', error);
	  }
	};
  
	useEffect(() => {
	  if (open) {
		setSelectedProfile('');
	  }
	}, [open]);
  
	return (
	  <Dialog
		open={open}
		disableEscapeKeyDown={true}
		onClose={(_, reason) => {
		  if (reason === 'backdropClick') {
			return;
		  }
		}}
		className={classes.dialog}
	  >
		<DialogTitle className={classes.title}>
		  Please select one of the profiles for this appointment.
		</DialogTitle>
		<DialogContent className={classes.content}>
		  <Typography variant="body1" className={classes.description}>
			This email address is associated with one or more patient profiles. 
			Please select one of the profiles to continue.
		  </Typography>
  
		  <RadioGroup
			className={classes.radioGroup}
			value={selectedProfile}
			onChange={(e) => setSelectedProfile(e.target.value)}
		  >
			{profiles.map((profile) => (
			  <FormControlLabel
				key={profile.id}
				value={profile.id.toString()}
				control={<Radio />}
				label={`${profile.firstName} ${profile.lastName}`}
				className={classes.formControl}
			  />
			))}
		  </RadioGroup>
  
		  <Box className={classes.buttonContainer}>
			<Button 
			  variant="text" 
			  onClick={handleSignOut} 
			  className={classes.signOutButton}
			  disabled={isLoading}
			>
			  SIGN OUT
			</Button>
			<LoadingButton
			  variant="contained"
			  color="primary"
			  onClick={handleConfirm}
			  disabled={!selectedProfile}
			  className={classes.continueButton}
			  loading={isLoading}
			>
			  CONTINUE
			</LoadingButton>
		  </Box>
		</DialogContent>
	  </Dialog>
	);
  }