import { makeStyles } from '@mui/styles';

import Section from './Section';

const useStyles = makeStyles(() => ({
	marginBottom: {
		paddingTop: '20px',
		marginBottom: '50px',
	},
}));

const RDLogoLight = () => {
	const classes = useStyles();
	return (
		<Section className={classes.marginBottom} centered={true} >
			<img src="/RDLogoLight.png" alt="Rocket Doctor logo" width="300" height="57" />
		</Section>
	);
};

export default RDLogoLight;
